<template>
    <div>
        <div class="border-0 m-0 d-flex flex-wrap align-items-center mt-5">
            <div class="border-0 m-0 d-flex flex-wrap align-items-center" style="width:100%">
                <b-form-group class="my-2" id="filter-group" label-for="filterInput">
                    <b-input-group>
                        <b-form-input v-model.lazy="search_filter" type="search" id="filterInput" placeholder="Rechercher" />
                        <b-input-group-append>
                            <b-button :disabled="!search_filter" @click="search_filter = ''">Effacer</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <b-table
                :items="links.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="search_filter"
                @filtered="onFiltered"
                responsive
                hover
                show-empty
                no-border-collapse
                style="vertical-align: middle; text-align: center"
                class="mx-0 p-0 my-5"
            >
                <template #cell(valid)="row">
                    {{ row.item.valid ? 'Oui' : 'Non' }}
                </template>
                <template #cell(expiry)="row">
                    {{ formatDate(row.item.expiry) }}
                </template>
                <template #cell(on)="row">
                    <div class="d-flex flex-column justify-content-around align-items-center">
                        <div v-if="row.item.on" class="text-primary">Actif</div>
                        <div v-else class="text-danger">Arrêté</div>
                    </div>
                </template>
                <template #cell(customers_id)="row">
                    {{ row.item.select_customer ? 'Choisi par le stagiaire' : row.item.customer.name }}
                </template>
                <template #cell(limit)="row">
                    {{ !row.item.limit && row.item.limit !== 0 ? 'Illimité' : row.item.limit }}
                </template>
                <template #cell(forceMail)="row">
                    <p v-if="row.item.forceMail">Avec adresse email</p>
                    <p v-else-if="row.item.forceNoMail">Sans adresse email</p>
                    <p v-else>Libre</p>
                </template>
                <template #cell(token)="row">
                    <div class="d-flex justify-content-center">
                        <b-button variant="primary" @click="token_selected = row.item.token" v-b-modal.modal-link>
                            Afficher le lien
                        </b-button>
                        <!--
                        <b-input-group class="m-0">
                            <b-form-input
                                style="overflow-x: auto"
                                class="border-primary text-bolder"
                                type="text"
                                readonly
                                :value="`${plateformeHref}/connexion/${row.item.token}`"
                            />
                        </b-input-group>
                        -->
                    </div>
                </template>
                <template #cell(Action)="row">
                    <div class="d-flex flex-row align-items-center w-25">
                        <button class="btn btn-clean btn-md svg-icon svg-icon-warning" @click="regenerateToken(row.item.id)">
                            <inline-svg src="/media/svg/icons/General/Update.svg" />
                        </button>
                        <button
                            :class="`btn btn-clean btn-md svg-icon ${row.item.on ? 'svg-icon-warning' : 'svg-icon-primary'}`"
                            @click="toogleActifLink(row.item.id, row.item.on)"
                        >
                            <inline-svg :src="`/media/svg/icons/Media/${row.item.on ? 'Pause' : 'Play'}.svg`" />
                        </button>
<!--                        <button class="btn btn-clean btn-md svg-icon svg-icon-primary">-->
<!--                            <inline-svg src="/media/svg/icons/General/Edit.svg" />-->
<!--                        </button>-->
                        <button class="btn btn-clean btn-md svg-icon svg-icon-danger" @click="deleteLink(row.item.id)">
                            <inline-svg src="/media/svg/icons/General/Trash.svg" />
                        </button>
                    </div>
                </template>
                <template #empty>
                    <h5>Aucun lien</h5>
                </template>
                <template #emptyfiltered>
                    <h5>Aucun résultat pour cette recherche</h5>
                </template>
            </b-table>
            <b-row class="justify-content-between align-items-center" style="width:100%">
                <b-col sm="5" md="4">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" />
                </b-col>
                <div>
                    <ul class="pagination">
                        <li class="page-item active">
                            <a class="page-link bg-primary">
                                Nombre de résultats : {{ search_filter ? totalRows : links ? links.data.length : '' }}
                            </a>
                        </li>
                    </ul>
                </div>
                <!--  <b-form-group
                    label="Nombre de résultats par page"
                    label-cols-sm="5"
                    label-cols-md="5"
                    label-cols-lg="5"
                    label-align-sm="center"
                    label-size="sm"
                    label-for="perPageSelect"
                    style="vertical-align : middle"
                    class="mr-2 align-items-center text-center"
                >
                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"> </b-form-select>
                </b-form-group> -->
            </b-row>
        </div>
        <b-modal id="modal-link" content-class="shadow">
            <h4 class="font-weight-bolder mx-auto text-center mt-2">
                <a class="text-primary" methods="_blank">{{ `${plateformeHref}/connexion/${token_selected}` }}</a>
            </h4>
            <p class="mb-2 mx-auto text-center">
                Communiquez le pour que les stagiaires s'inscrivent à la classe
            </p>
            <template #modal-footer="{ok}">
                <b-button variant="primary" hidden @click="copy(false)">Copier</b-button>
                <b-button variant="secondary" @click="ok">Fermer</b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { LIST_LINK } from '@/core/services/store/api/entry-link.service';
import ApiService from '@/core/services/api.service';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    props: {
        classCustomerId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            test: null,
            selectedCustomer: 0,
            selectedClasse: 0,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, { value: 100, text: 'Le plus possible' }],
            search_filter: null,
            isBusy: false,
            sortBy: 'title',
            sortDesc: false,
            fields: [
                { key: 'customers_id', label: 'Etabissement de rattachement', sortable: true },
                { key: 'expiry', label: "Date d'expiration", sortable: true },
                { key: 'token', label: 'Url', thStyle: 'width: 15em', sortable: true },
                { key: 'limit', label: 'Utilisation restante', thStyle: 'width: 15em', sortable: true },
                { key: 'forceMail', label: 'Type d\'inscription', thStyle: 'width: 15em', sortable: true },
                { key: 'valid', label: "Validation d'inscription", thStyle: 'width: 15em', sortable: true },
                { key: 'on', label: 'Statut', sortable: true },
                { key: 'Action', label: '', thStyle: 'width: 5%', sortable: false }
            ],
            token_selected: null
        };
    },
    mounted() {
        this.getLinks();
    },
    computed: {
        ...mapGetters({
            currentUser: 'currentUser',
            links: 'getLink'
        }),
        plateformeHref() {
            return `${process.env.VUE_APP_PLATEFORME_URL}`;
        }
    },
    watch: {
        currentPage: function() {
            return this.getLinks();
        }
    },
    methods: {
        getLinks() {
            let params = {
                classCustomerId: this.classCustomerId,
                params: {
                    page: this.currentPage,
                    'filter[classe.title]': this.search_filter
                }
            };
            this.$store.dispatch(LIST_LINK, params);
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        toogleActifLink(link_id, status) {
            const data = { on: status ? 0 : 1 };
            this.updateLink(link_id, data);
        },
        regenerateToken(link_id) {
            let new_token = 'AC';
            const possible = 'stuvwCDM012EFGghijklmnopqHIJWXYZKL34RSABdefrTU56789abcxyzNPQV';
            for (let i = 0; i < 6; i++) new_token += possible.charAt(Math.floor(Math.random() * possible.length));
            this.updateLink(link_id, { token: new_token });
        },
        updateLink(link_id, data) {
            ApiService.put(`academy/entryLink/${link_id}`, data).then(() => {
                this.getLinks();
            });
        },
        deleteLink(link_id) {
            this.$bvModal
                .msgBoxConfirm('Êtes vous sûr de vouloir supprimer ce lien ?', {
                    title: 'Veuillez confirmer',
                    size: 'sm',
                    buttonSize: 'md',
                    okVariant: 'danger',
                    okTitle: 'Oui, je suis sûre',
                    cancelTitle: 'Non',
                    footerClass: 'p-2 d-flex justify-content-around',
                    bodyClass: 'font-weight-bold',
                    hideHeaderClose: false
                })
                .then(value => {
                    if (value) {
                        ApiService.delete(`academy/entryLink/${link_id}`).then(() => {
                            this.getLinks();
                        });
                    }
                });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        copy(success) {
            const body = success
                ? 'Le lien est copié avec succès dans votre presse-papiers. !'
                : "Une erreur s'est produite, nous n'avons pas été en mesure de copier le lien.";
            this.$bvToast.toast(body, {
                title: `${success ? 'Succès' : 'Erreur'}`,
                solid: true,
                variant: success ? 'success' : 'danger'
            });
        }
    }
};
</script>
<style>
table th,
table td {
    vertical-align: middle !important;
}
#filter-group {
    min-width: 60rem;
}
</style>
